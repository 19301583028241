import { Platform } from '@angular/cdk/platform';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateFnsAdapter, MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    CoreModule,
    CpButtonsModule,
    CpModalModule,
    CpStepperStepComponent,
    CpTextButtonComponent,
    ToasterModule
} from '@concession-portal/ng-dso-ui-components-fe';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { OAuthModule } from 'angular-oauth2-oidc';
import { de } from 'date-fns/locale';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PermissionInterceptor } from './auth/interceptor/permission.interceptor';
import { PhaseTransitionModule } from './feature-modules/phase-transition/phase-transition.module';

registerLocaleData(localeDe, localeDeExtra);

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        OAuthModule.forRoot(),
        CoreModule.forRoot(environment.authConfig, environment.authModuleConfig),
        CpButtonsModule,
        CpModalModule,
        CpStepperStepComponent,
        CpTextButtonComponent,
        FormsModule,
        MatDateFnsModule,
        NgxChartsModule,
        PhaseTransitionModule,
        ReactiveFormsModule,
        ToasterModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: PermissionInterceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: 'de-DE',
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: de,
        },
        {
            provide: DateAdapter,
            useClass: DateFnsAdapter,
            deps: [MAT_DATE_LOCALE, Platform],
        },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    constructor() {
    }
}
