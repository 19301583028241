import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { COMMODITY, PHASE } from '../../../../core/enums';
import { SelectItem } from '../../../../core/items';
import { EMPTY_PHASE_PROCEDURE_MODEL, GridOperatorModel, PhaseModel, UserModel } from '../../../../core/models';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { getPhaseName, getStartPhases, isPhaseCompleted } from '../../../../core/utils/phase.util';
import { applyUserFilter } from '../../../../core/utils/user.util';
import { mapToSelectItems } from '../../../../core/utils/item.util';
import { GridOperatorFacade, UserFacade } from '../../../../core/facades';


@Component({
    selector: 'portal-select-next-phase',
    templateUrl: './select-next-phase.component.html',
    styleUrls: ['./select-next-phase.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SelectNextPhaseComponent implements OnInit, OnDestroy {
    @Input()
    public data: PhaseModel;

    @Output()
    public dataUpdated = new EventEmitter<PhaseModel>();

    @Output()
    public dataValid = new EventEmitter<boolean>();

    public COMMODITY = COMMODITY;
    public PHASE = PHASE;
    public form: UntypedFormGroup;
    public phases: SelectItem[] = [];
    public nextPhaseHasEndDate: boolean = true;
    public today: Date = new Date();
    public gridOperatorItems$: Observable<SelectItem[]>;
    public users$: Observable<UserModel[]>;

    private userFilter = '';
    private refreshUsers = new BehaviorSubject<boolean>(true);
    private ngDestroy: Subject<void> = new Subject();

    constructor(
        private gridOperatorFacade: GridOperatorFacade,
        private userFacade: UserFacade,
    ) {
        this.gridOperatorFacade.loadGridOperators();
        this.gridOperatorItems$ = this.gridOperatorFacade.gridOperators$.pipe(
            map((gridOperators: GridOperatorModel[]) => mapToSelectItems(gridOperators))
        );

        this.userFacade.loadUsers();
        this.users$ = this.refreshUsers.pipe(
            switchMap(() => this.userFacade.users$),
            map((users: UserModel[]) => applyUserFilter(users, this.userFilter)),
        );

        this.form = new FormGroup({
            endDate: new FormControl<string>('', [Validators.required]),
            gridOperatorId: new FormControl<string>('', [Validators.required]),
            ownerKid: new FormControl<string>('', [Validators.required]),
            nextPhase: new FormControl<PHASE>(null, [Validators.required]),
        });
    }

    public ngOnInit(): void {
        this.setSelectablePhases();

        this.initForm();
        this.bindFormChanges();
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public filterUsers(filter: string): void {
        this.userFilter = filter.toLowerCase();
        this.refreshUsers.next(true);
    }

    private setSelectablePhases(): void {
        const phases: SelectItem[] = [];

        getStartPhases().forEach((phase: PHASE) => {
            phases.push({
                value: phase,
                disabled: isPhaseCompleted(phase, this.data.currentPhase),
                text: getPhaseName(phase)
            })
        });

        if (this.data.commodity === COMMODITY.GAS) {
            phases.find((p: SelectItem) => p.value === PHASE.QUANTITY_STRUCTURE_CREATION_START).disabled = true;
        }

        if (this.data.currentPhase === PHASE.FINAL_PROCEDURE_END) {
            phases.find((p: SelectItem) => p.value === PHASE.INDICATIVE_PROCEDURE_START).disabled = false;
        }

        if ([PHASE.QUANTITY_STRUCTURE_CREATION_END, PHASE.PUBLICATION_END].includes(this.data.currentPhase)) {
            phases.find((p: SelectItem) => p.value === PHASE.REPORTING_START).disabled = true;
        }

        this.phases = phases;
    }

    private initForm() {
        this.form.controls.endDate.setValue(this.data.endDate, { emitEvent: false });
        this.form.controls.gridOperatorId.setValue(this.data.gridOperatorId, { emitEvent: false });
        this.form.controls.ownerKid.setValue(this.data.ownerKid, { emitEvent: false });
        this.form.controls.nextPhase.setValue(this.data.nextPhase);

        if (this.data.nextPhase === PHASE.REPORTING_START) {
            this.nextPhaseHasEndDate = false;
        }

        this.handleFormNextPhaseChange(this.data.nextPhase);
        this.dataValid.emit(this.form.valid);
    }

    private bindFormChanges(): void {
        this.form.controls.nextPhase.valueChanges.pipe(
            takeUntil(this.ngDestroy)
        )
        .subscribe((value: PHASE) => {
            this.handleFormNextPhaseChange(value);
        });

        this.form.valueChanges.pipe(
            takeUntil(this.ngDestroy)
        ).subscribe(() => {
            const data: PhaseModel = {
                ...this.data,
                endDate: this.form.controls.endDate.value,
                gridOperatorId: this.form.controls.gridOperatorId.value,
                ownerKid: this.form.controls.ownerKid.value,
                nextPhase: this.form.controls.nextPhase.value
            };

            this.dataUpdated.emit(data);
            this.dataValid.emit(this.form.valid);
        });
    }

    private handleFormNextPhaseChange(phase: PHASE): void {
        switch (phase) {
            case PHASE.REPORTING_START:
                this.removeEndDateValidator();
                this.nextPhaseHasEndDate = false;
                break;

            case PHASE.INDICATIVE_PROCEDURE_START:
            case PHASE.FINAL_PROCEDURE_START:
                this.addEndDateValidator();

                if (!this.data.procedure) {
                    this.data.procedure = {
                        ...EMPTY_PHASE_PROCEDURE_MODEL,
                        activityIds: [this.data.activityId]
                    };
                }
                this.nextPhaseHasEndDate = true;
                break;

            default:
                this.addEndDateValidator();
                this.nextPhaseHasEndDate = true;
                break;

        }
    }

    private addEndDateValidator(): void {
        this.form.controls.endDate.addValidators(Validators.required);
        this.form.controls.endDate.updateValueAndValidity();
    }

    private removeEndDateValidator(): void {
        this.form.controls.endDate.clearValidators();
        this.form.controls.endDate.updateValueAndValidity();
    }
}
