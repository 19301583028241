import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
    CpButtonsModule,
    CpCellContentComponent,
    CpDialogLayoutComponent,
    CpFormColumnLayoutComponent,
    CpHeadlineComponent, CpIntegerValidatorDirective,
    CpLabelComponent,
    CpMaterialExtensionsModule,
    CpReadOnlyFormFieldComponent,
    CpSelectFilterComponent,
    CpSelectFilterDirective,
    CpStepperStepComponent,
    CpSvgIconComponent
} from '@concession-portal/ng-dso-ui-components-fe';
import { CpWizardStepIndicatorComponent } from '../../component/wizard-step-indicator/wizard-step-indicator.component';
import { ReportingBaseDataComponent } from './components/reporting-base-data/reporting-base-data.component';
import { ReportingContractsDataComponent } from './components/reporting-contracts-data/reporting-contracts-data.component';
import { ReportingSelectMunicipalitiesComponent } from './components/reporting-select-municipalites/reporting-select-municipalities.component';
import { SelectNextPhaseComponent } from './components/select-next-phase/select-next-phase.component';
import { StartProcedurePhaseComponent } from './components/start-procedure-phase/start-procedure-phase.component';
import { EndPhaseDialogComponent } from './dialogs/end-phase-dialog/end-phase-dialog.component';
import { StartPhaseDialogComponent } from './dialogs/start-phase-dialog/start-phase-dialog.component';
import { ConcessionKeyValidatorDirective } from './directives';

@NgModule({
    declarations: [
        SelectNextPhaseComponent,
        StartPhaseDialogComponent,
        EndPhaseDialogComponent,
        StartProcedurePhaseComponent,
        ReportingBaseDataComponent,
        ReportingSelectMunicipalitiesComponent,
        ReportingContractsDataComponent,
    ],
    imports: [
        CommonModule,
        ConcessionKeyValidatorDirective,
        CpButtonsModule,
        CpCellContentComponent,
        CpDialogLayoutComponent,
        CpHeadlineComponent,
        CpIntegerValidatorDirective,
        CpLabelComponent,
        CpReadOnlyFormFieldComponent,
        CpSelectFilterComponent,
        CpSelectFilterDirective,
        CpStepperStepComponent,
        CpSvgIconComponent,
        CpWizardStepIndicatorComponent,
        FormsModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTableModule,
        MatTooltipModule,
        ReactiveFormsModule,
        CpFormColumnLayoutComponent,
        CpMaterialExtensionsModule
    ],
})
export class PhaseTransitionModule {
}
